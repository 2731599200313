import React, { FC, useEffect } from 'react';
import { Dialog, Text, Button } from 'wix-ui-tpa/cssVars';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { DataHook } from '../../../../../constants/DataHook';
import {
  BiActionType,
  Experiment,
  ModalType,
  MY_ACCOUNT_REFERRAL,
} from '../../../../../types';
import { useBi } from '../../../../../hooks';
import { useMember, useMemberActions } from '../../../../../contexts/widget';
import { NewActionableMemberRow } from '../../common/ActionableMemberRow';
import { classes, st } from './NewBlockedMembersModal.st.css';
import BlockedMembersModalEmptyState from './BlockedMembersModalEmptyState';

export const NewBlockedMembersModal: FC<ModalType> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const { experiments } = useExperiments();
  const { blockedMembers } = useMember();
  const { unblockMember } = useMemberActions();
  const biLogger = useBi();

  const shouldShowEmptyState = experiments.enabled(
    Experiment.ShowBlockedMembersModalEmptyState,
  );

  useEffect(() => {
    if (!blockedMembers.length && !shouldShowEmptyState) {
      onClose();
    }
  }, [blockedMembers.length, onClose, shouldShowEmptyState]);

  const onUnblock = (id: string, name: string) => {
    biLogger.ctaButtonClickedWithProps(BiActionType.Unblock_Member_Confirm, {
      member_chosen: id,
      formOrigin: MY_ACCOUNT_REFERRAL,
    });
    return unblockMember({ id, name });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      closeButtonAriaLabel={t('app.widget.modals.blocked-members.close-btn')}
      contentClassName={st(classes.root, { mobile: isMobile, rtl: isRTL })}
      childrenWrapperClassName={classes.content}
      aria-label={t('app.widget.modals.blocked-members.title')}
    >
      <div
        className={classes.contentInner}
        data-hook={DataHook.BlockedMembersModal}
      >
        <div className={classes.heading}>
          <Text className={classes.title} tagName="h2">
            {t('app.widget.modals.blocked-members.title')}
          </Text>
          <Text className={classes.subtitle} tagName="p">
            {t('app.widget.modals.blocked-members.subtitle')}
          </Text>
        </div>

        <div className={classes.blockedMembersContainer}>
          {blockedMembers.length
            ? blockedMembers.map((member) => (
                <NewActionableMemberRow
                  member={member}
                  onAction={() => onUnblock(member.id, member.name)}
                  buttonText={t('app.widget.modals.blocked-members.unblock')}
                />
              ))
            : shouldShowEmptyState && <BlockedMembersModalEmptyState />}
        </div>

        <div className={classes.footer}>
          <Button upgrade onClick={onClose} className={classes.button}>
            {t('app.widget.modals.blocked-members.close-btn')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
